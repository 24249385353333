import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";

const subTitle = "About Our ETI";
const title = "Our Promise: Building Skills, Ensuring Quality Education";
const desc = "we prioritize delivering streamlined and user-friendly processes that encourage efficient functionality, transparent communication and ensuring an enhanced learning experience for our students";

const year = "10+";
const expareance = "Years Of Experiences";

const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/06.webp',
        imgAlt: 'about icon ETI Institutions',
        title: 'Experienced Faculty',
        desc: 'Our institution is proud to have skilled instructors who bring their expertise and industry knowledge to provide quality education and guidance to our students.',
    },
    {
        imgUrl: 'assets/images/about/icon/05.webp',
        imgAlt: 'about icon ETI Institutions',
        title: 'Recognized Certifications',
        desc: 'At ETI Institutions, we offer valuable certifications upon successful completion of our courses, validating the skills and knowledge acquired by our students.',
    },
    {
        imgUrl: 'assets/images/about/icon/04.webp',
        imgAlt: 'about icon ETI Institutions',
        title: 'Flexible Learning Options',
        desc: 'We provide online classes to accommodate the needs of our students, offering a convenient and accessible way to pursue their education without compromising quality.',
    },
];


const AboutPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeader title={'About ETI'} curPage={'About'} />
            <div className="about-section style-3 padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-center">
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/01.webp" alt="about" />
                                </div>
                                <div className="abs-thumb">
                                    <img src="assets/images/about/male-student.webp" alt="about" />
                                </div>
                                <div className="about-left-content">
                                    <h3>{year}</h3>
                                    <p>{expareance}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-right">
                                <div className="section-header">
                                    <span className="subtitle">{subTitle}</span>
                                    <h2 className="title">{title}</h2>
                                    <p>{desc}</p>
                                </div>
                                <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Student /> */}
            <Skill />
            <AchievementTwo />
            <Footer />
        </Fragment>
    );
}

export default AboutPage;
