import { Fragment, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";

import '../assets/css/my/apply.css';

import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumber } from 'react-phone-number-input'

import {env} from '../env'

const apiUrl = env.REACT_APP_API_URL;

const title = "Start Your Career";
const btnText = "Apply";

const ApplyPage = () => {
    const [message, setMessage] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [course, setCourse] = useState("");
    const [error, setError] = useState(null);

    function handleTextChange(e) {
        setName(e.target.value);
    }

    function handleCourseChange(e) {
        setCourse(e.target.value);
    }

    function submitForm(e) {
        e.preventDefault();
        fetch(apiUrl + '/api/apply', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                phone_number: formatPhoneNumber(phone),
                course: course
            }),
        })
            .then(response => response.json())
            .then(data => {
                if (data.message) {
                    setMessage(data.message);
                    setError(null);
                } else {
                    setError("An error occurred while submitting the form.");
                    setMessage("");
                }
            })
            .catch(error => {
                setError("An error occurred while submitting the form.");
                setMessage("");
            });
    }

    return (
        <Fragment>
            <Header />
            <div className="login-section padding-tb section-bg section-form">
                <div className="container">
                    <div className="account-wrapper">
                        <h3 className="title">{title}</h3>
                        <div className="account-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    value={name}
                                    onChange={handleTextChange}
                                    name="name"
                                    placeholder="Your Name"
                                />
                            </div>
                            <div className="form-group">
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={setPhone}
                                    defaultCountry="IN"
                                />
                            </div>
                            <div className="form-group">
                                <select name="course" id="course-select" value={course} onChange={handleCourseChange}>
                                    <option value="no">Please Select Course</option>
                                    <option value="Automobile Engineering">Automobile Engineering</option>
                                    <option value="A/C Refrigeration Engineering">A/C Refrigeration Engineering</option>
                                    <option value="Civil Engineering">Civil Engineering</option>
                                    <option value="Electronics Engineering">Electronics Engineering</option>
                                    <option value="Electrical Engineering">Electrical Engineering</option>
                                    <option value="Computer Engineering">Computer Engineering</option>
                                </select>
                            </div>
                            {error && <p className="error-message">{error}</p>}
                            {message && <p className="success-message">{message}</p>}
                            <div className="form-group">
                                <button onClick={submitForm} className="lab-btn"><span>{btnText}</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default ApplyPage;
