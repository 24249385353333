import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import CourseSideDetail from "../../component/sidebar/course-detail";


const AcPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo
                title={"A/C Refrigeration Engineering"}
                desc={"A/C Refrigeration Engineering is the application of engineering principles to the design, installation, and maintenance of A/C and refrigeration systems."}
                author={"Usman"}
                image={"https://randomuser.me/api/portraits/men/62.jpg"}
            />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div class="course-content">
                                            <h3>Course Overview</h3>
                                            <p>This course will teach you the fundamentals of A/C and refrigeration engineering. You will learn about the different types of A/C and refrigeration systems, how they work, and how to maintain them. You will also learn about the safety precautions that must be taken when working with A/C and refrigeration systems.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul class="lab-ul">

                                                <li><i className="icofont-tick-mark" /> 	Engineering   </li>
                                                <li><i className="icofont-tick-mark" />	Safety rules, basic workshop practice, RAC system   </li>
                                                <li><i className="icofont-tick-mark" /> 	Mechanical and electrical components of RAC system, Compressor and its working  </li>
                                                <li><i className="icofont-tick-mark" /> Components of refrigeratorevaporator, expansion devices and condensor. Refigerants    </li>
                                                <li><i className="icofont-tick-mark" /> 	Installation, Service and maintanance of refigeration system  </li>
                                                <li><i className="icofont-tick-mark" />	Safety rules, basic workshop practice, RAC system   </li>
                                                <li><i className="icofont-tick-mark" /> 	Mechanical and electrical components of RAC system, Compressor and its working  </li>
                                                <li><i className="icofont-tick-mark" />	Understand installation, servicing and maintenance of Air conditioning systems.   </li>
                                                <li><i className="icofont-tick-mark" />	Know repairing and servicing of Ice candy plant, Ice cube machine etc.   </li>
                                                <li><i className="icofont-tick-mark" /> 	Know psychrometry and properties of moist air.  </li>
                                                <li><i className="icofont-tick-mark" /> 	Centralized AC system, components, Maintenance.  </li>
                                                <li><i className="icofont-tick-mark" />	Trouble shooting of AC systems, Practical applications   </li>
                                                <li><i className="icofont-tick-mark" />	RAC systems, Repair and maintenanace.   </li>
                                                <li><i className="icofont-tick-mark" />	Psychrometry, AC components Repair and maintenance.   </li>

                                            </ul>
                                            <p>In this course take you from the fundamentals and concepts of data modeling all the way through anumber  of best practices and techniques that you’ll need to build data models in your organization. You’ll find many examples that clearly the key covered the course</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default AcPage;