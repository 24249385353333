import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import CourseSideDetail from "../../component/sidebar/course-detail";

const AcPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo
                title={"Automobile Engineering"}
                desc={"Automobile engineering is the application of engineering principles to the design, development, and manufacturing of automobiles."}
                author={"Arun"}
                image={"https://randomuser.me/api/portraits/men/63.jpg"}
            />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div class="course-content">
                                            <h3>Course Overview</h3>
                                            <p>This course will teach you the fundamentals of automobile engineering. You will learn about the different components of automobiles, how they work, and how to maintain them. You will also learn about the safety precautions that must be taken when working on automobiles.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul class="lab-ul">
                                                <li><i class="icofont-tick-mark"></i>Automobile tools and equipment</li>
                                                <li><i class="icofont-tick-mark"></i>Construction Details of Engine</li>
                                                <li><i class="icofont-tick-mark"></i>Lubrication System</li>
                                                <li><i class="icofont-tick-mark"></i>Differential and Rear Axle</li>
                                                <li><i class="icofont-tick-mark"></i>Front axle and steering   </li>
                                                <li><i class="icofont-tick-mark"></i> 	Dismantling and assembling of engine  </li>
                                                <li><i class="icofont-tick-mark"></i>  Clutch Dismantling and Assembling  </li>
                                                <li><i class="icofont-tick-mark"></i>  Clutch Dismantling and Assembling  </li>
                                                <li><i class="icofont-tick-mark"></i>	Automatic Gear box dismantling & Assembling   </li>
                                                <li><i class="icofont-tick-mark"></i>  	Servicing of Petrol Fuel System </li>
                                                <li><i class="icofont-tick-mark"></i>	Servicing of Diesel Fuel System   </li>
                                                <li><i class="icofont-tick-mark"></i>	Servicing of Cooling System   </li>
                                                <li><i class="icofont-tick-mark"></i> 	Brake System  </li>
                                                <li><i class="icofont-tick-mark"></i>	Chassis and Suspenssion   </li>
                                                <li><i class="icofont-tick-mark"></i> 	Basic Electrical & Electronic  </li>
                                                <li><i class="icofont-tick-mark"></i>	Ignition System   </li>
                                                <li><i class="icofont-tick-mark"></i> 	Charging System  </li>
                                                <li><i class="icofont-tick-mark"></i>	Electric and Hybrid Vehicles   </li>
                                                <li><i class="icofont-tick-mark"></i>	Automatic Climate Controls   </li>
                                                <li><i class="icofont-tick-mark"></i>	Fault Diagnostic system   </li>
                                                <li><i class="icofont-tick-mark"></i> 	Propeller shaft Servicing  </li>
                                                <li><i class="icofont-tick-mark"></i>	Rear axle Servicing   </li>
                                                <li><i class="icofont-tick-mark"></i>	Steering Gear Box Dismantling and Assembling   </li>
                                                <li><i class="icofont-tick-mark"></i> 	Wheel Balancing  </li>
                                                <li><i class="icofont-tick-mark"></i>	Wheel Alignment   </li>
                                                <li><i class="icofont-tick-mark"></i> 	Lighting systems and accessories  </li>                                           

                                               
                                            </ul>
                                            <p>In this course, you will learn about the different components of automobiles, such as the engine, transmission, brakes, and steering system. You will also learn about how these components work together to make the automobile move. In addition, you will learn about the safety precautions that must be taken when working on automobiles.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default AcPage;