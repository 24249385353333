import { Fragment, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";
import axios from "axios";
import {env} from '../env'

const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle = "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Send our Message";


const contactList = [
    {
        imgUrl: 'assets/images/icon/01.webp',
        imgAlt: 'contact icon',
        title: 'Office Address',
        desc: 'Camp Road, Areekode, Kerala - 673639',
    },
    {
        imgUrl: 'assets/images/icon/02.webp',
        imgAlt: 'contact icon',
        title: 'Phone number',
        desc: '+91 9847 406 431',
    },
    {
        imgUrl: 'assets/images/icon/03.webp',
        imgAlt: 'contact icon',
        title: 'Send email',
        desc: 'info@etiinstitution.org',
    },
    {
        imgUrl: 'assets/images/icon/04.webp',
        imgAlt: 'contact icon',
        title: 'Our website',
        desc: 'www.etiinstitution.org',
    },
]

const apiUrl = env.REACT_APP_API_URL;

const ContactPage = () => {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [number, setNumber] = useState();
    const [subject, setSubject] = useState();
    const [message, setMessage] = useState();

    const [submittedMessage, setSubmittedMessage] = useState("");

    function submitForm() {
        axios
            .post(apiUrl + "/api/contact-form", { 'name': name, 'email': email, 'number': number, 'subject': subject, 'message': message })
            .then(response => {
                setSubmittedMessage(response.data.success);
            })
            .catch(error => {
                console.error(error.message);
            });
    }

    return (
        <Fragment>
            <Header />
            <PageHeader title={'Get In Touch With Us'} curPage={'Contact Us'} />
            <div className="map-address-section padding-tb section-bg">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{subTitle}</span>
                        <h2 className="title">{title}</h2>
                    </div>
                    <div className="section-wrapper">
                        <div className="row flex-row-reverse">
                            <div className="col-xl-4 col-lg-5 col-12">
                                <div className="contact-wrapper">
                                    {contactList.map((val, i) => (
                                        <div className="contact-item" key={i}>
                                            <div className="contact-thumb">
                                                <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                            </div>
                                            <div className="contact-content">
                                                <h6 className="title">{val.title}</h6>
                                                <p>{val.desc}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-12">
                                <GoogleMap />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact-section padding-tb">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{conSubTitle}</span>
                        <h2 className="title">{conTitle}</h2>
                    </div>
                    <div className="section-wrapper">
                        <form className="contact-form">
                            {(submittedMessage !== "") ?
                                <div className="alert alert-success" role="alert">
                                    Form Submitted Successfully
                                </div>
                                :
                                ""
                            }
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name *"
                                    onChange={setName}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Your Email *"
                                    onChange={setEmail}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="number"
                                    placeholder="Mobile Number *"
                                    onChange={setNumber}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder="Your Subject *"
                                    onChange={setSubject}
                                />
                            </div>
                            <div className="form-group w-100">
                                <textarea
                                    rows="8"
                                    type="text"
                                    placeholder="Your Message"
                                    onChange={setMessage}
                                ></textarea>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button onClick={submitForm} className="lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}



export default ContactPage;