import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import CourseSideDetail from "../../component/sidebar/course-detail";

const ECEPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo
                title={"Electronics and Communication Engineering"}
                desc={"Electronics and Communication Engineering focuses on the design, development, and implementation of electronic devices and communication systems."}
                author={"Sarah"}
                image={"https://randomuser.me/api/portraits/women/82.jpg"}
            />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Course Overview</h3>
                                            <p>The Electronics and Communication Engineering course provides a strong foundation in electronics, communication systems, and related technologies. You will learn about electronic circuit design, digital electronics, microprocessors, communication networks, and signal processing.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>	Engineering Drawing  </li>
                                                <li><i className="icofont-tick-mark"></i>	understand the fundamentals of Electronics which includes Understand about Basic Electricity ,Understand Passive and Active Components , Understand different types of Resistors,.Capacitors and Transformers ,etc., </li>
                                                <li><i className="icofont-tick-mark"></i>	Orthographic Projection </li>
                                                <li><i className="icofont-tick-mark"></i>	Digital Electronics , Electronic Devices and Circuits </li>
                                                <li><i className="icofont-tick-mark"></i>	Communication Systems and Industrial Safety </li>
                                                <li><i className="icofont-tick-mark"></i>	ELECTRONIC HOME APPLIANCES </li>
                                                <li><i className="icofont-tick-mark"></i>	Basic Electronics Workshop </li>
                                                <li><i className="icofont-tick-mark"></i>	Communication Systems and Industrial Safety Lab </li>
                                                <li><i className="icofont-tick-mark"></i>	BASICS OF CABLE TV </li>
                                                <li><i className="icofont-tick-mark"></i>	BASICS OF UPS AND INVERTER </li>
                                                <li><i className="icofont-tick-mark"></i>	BASICS OF SOLAR ENGINEERING </li>
                                                <li><i className="icofont-tick-mark"></i>	DTH INSTALLATION AND SERVICE </li>
                                            </ul>
                                            <p>Throughout the course, you will gain practical skills through hands-on projects, laboratory work, and industry internships. You will also have opportunities to explore advanced topics such as robotics, image processing, and wireless sensor networks. By the end of the course, you will be equipped with the knowledge and skills required to excel in the field of Electronics and Communication Engineering.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default ECEPage;
