import React from 'react'
import PageHeader from '../component/layout/pageheader'
import Header from '../component/layout/header'
import Footer from '../component/layout/footer'

export default function Affiliation() {
    return (
        <>
            <Header />
            <PageHeader title={'Question Papers'} curPage={'question paper'} />
            <div className='container'>
                <><p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "0cm", marginLeft: "18.55pt", textAlign: "center", textIndent: "0cm", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}><strong><span style={{ fontSize: 27, lineHeight: "107%" }}>KGCE  </span></strong></p>
                    <p style={{ marginTop: "0cm", marginRight: ".6pt", marginBottom: ".05pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>SBTE, Kerala is the affiliating body of KGCE programs of various Institutes in Kerala, Periodical revision of various KGCE programs is undertaken by State Institute of Technical teachers Training and</p>
                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "7.95pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>Research (SITTTR) which is the academic body of the Directorate of Technical Education, Kerala.</p>
                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "8.0pt", marginLeft: "2.95pt", textAlign: "center", textIndent: "0cm", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}> </p>
                    <p style={{ marginTop: "0cm", marginRight: "-.5pt", marginBottom: "8.1pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>KGCE certificate is an academic credential issued by the SBTE to a candidate on successful completion of the program as envisaged in the curriculum.</p>
                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "7.9pt", marginLeft: "2.95pt", textAlign: "center", textIndent: "0cm", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}> </p>
                    <p style={{ marginTop: "0cm", marginRight: ".6pt", marginBottom: ".05pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>The Kerala Government Certificate Examination programs courses are two year programs conducted at Institutions whose KGCE programs are approved by DTE Kerala and affiliated to SBTE Kerala. The</p>
                    <p style={{ marginTop: "0cm", marginRight: ".6pt", marginBottom: "8.1pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>Revision 22 curriculum is prepared by maintaining the durations of the programs. But, in order to enhance the employability of the candidates, a shift in emphasis from theoretical aspects to practical work skill training and actual field applications are made.</p>
                    <p style={{ marginTop: "0cm", marginRight: ".6pt", marginBottom: ".05pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>Considering the changes in Technology, KGCE in Electronics and Communication Engineering and Radio & Television Engineering are combined, revamped and revised as Electronics Engineering.</p>
                    <p style={{ marginTop: "0cm", marginRight: ".2pt", marginBottom: ".05pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>KGCE in Computer Engineering is introduced, considering the wide applications of the field, from</p>
                    <p style={{ marginTop: "0cm", marginRight: ".15pt", marginBottom: ".05pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>Revision 22 scheme onwards. Each branch of study may include Theory, Engineering Drawing,</p>
                    <p style={{ marginTop: "0cm", marginRight: ".6pt", marginBottom: ".05pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>Practical courses, OJT and skill training courses. This Curriculum Revision 22 framework which includes Rules & Regulations, Program scheme, Syllabus, NSQF Qualification pack scheme shall be</p>
                    <p style={{ marginTop: "0cm", marginRight: ".6pt", marginBottom: "8.1pt", marginLeft: "1.1pt", textAlign: "center", textIndent: "-.5pt", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}>implemented for the eight KGCE programs listed above conducted in Full time /Part time mode from the academic year 2022 onwards. The Institutions shall fulfill all facilities such as Infrastructure, Faculty and other requirements to offer the programs as per norms specified in Revision 22 for the Total Approved Intake including Part time.</p>
                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "7.9pt", marginLeft: "2.95pt", textAlign: "center", textIndent: "0cm", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}> </p>
                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "18.55pt", marginLeft: "2.95pt", textAlign: "center", textIndent: "0cm", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}> </p>
                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: "0cm", marginLeft: "5.45pt", textAlign: "center", textIndent: "0cm", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black" }}><strong><span style={{ fontSize: 29, lineHeight: "107%" }}> </span></strong></p>
                    <p style={{ marginTop: "0cm", marginRight: "0cm", marginBottom: ".05pt", marginLeft: ".5pt", textAlign: "left", textIndent: "0cm", lineHeight: "107%", fontSize: 15, fontFamily: "\"Calibri\",sans-serif", color: "black", margin: "0cm" }}><strong> </strong></p></>
            </div>
            <Footer />
        </>
    )
}
