
import { Link } from "react-router-dom";

const subTitle = "Popular Category";
const title = "Empower Your Future";
const btnText = "Apply Now";

const categoryList = [
    {
        imgUrl: 'assets/images/category/icon/01.webp',
        imgAlt: 'an engine',
        title: 'Automobile Engineering',
        link: '/automobile-engg'
    },
    {
        imgUrl: 'assets/images/category/icon/02.webp',
        imgAlt: 'computer and mobile',
        title: 'A/C Refrigeration Engineering',
        link: '/ac-engg'
    },
    {
        imgUrl: 'assets/images/category/icon/03.webp',
        imgAlt: 'some enquipement',
        title: 'Civil Engineering',
        link: '/civil-engg'
    },
    {
        imgUrl: 'assets/images/category/icon/programming.webp',
        imgAlt: 'some enquipement',
        title: 'Computer Engineering',
        link: '/computer-engg'
    },
    {
        imgUrl: 'assets/images/category/icon/04.webp',
        imgAlt: 'Electronics Engineering logo',
        title: 'Electronics Engineering',
        link: '/eletronics-engg'
    },
    {
        imgUrl: 'assets/images/category/icon/05.webp',
        imgAlt: 'Electrical Engineering logo',
        title: 'Electrical Engineering',
        link: '/electrical-engg'
    }
]


const Category = () => {
    return (
        <div className="category-section padding-tb">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row g-2 justify-content-center row-cols-xl-6 row-cols-md-3 row-cols-sm-2 row-cols-1">
                        {categoryList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="category-item text-center">
                                    <div className="category-inner">
                                        <div className="category-thumb">
                                            <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                                        </div>
                                        <div className="category-content">
                                            <Link to={`${val.link}`}><h6>{val.title}</h6></Link>
                                            <span>{val.count}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="text-center mt-5">
                        <Link to="/apply" className="lab-btn"><span>{btnText}</span></Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Category;