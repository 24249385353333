import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import CourseSideDetail from "../../component/sidebar/course-detail";


const AcPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo
                title={"Civil Engineering"}
                desc={"Civil Engineering is the application of engineering principles to the design, construction, and maintenance of the built environment."}
                author={"ramesh"}
                image={"https://randomuser.me/api/portraits/men/64.jpg"}
            />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div class="course-content">
                                            <h3>Course Overview</h3>
                                            <p>This course will teach you the fundamentals of civil engineering. You will learn about the different types of civil engineering projects, how they are designed and constructed, and the challenges that civil engineers face. You will also learn about the ethical and professional responsibilities of civil engineers.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul class="lab-ul">
                                                <li><i class="icofont-tick-mark"></i>	Engineering Drawing </li>
                                                <li><i class="icofont-tick-mark"></i>	Materials, Construction & Surveying </li>
                                                <li><i class="icofont-tick-mark"></i>	Employability Skills & Entrepreneurship </li>
                                                <li><i class="icofont-tick-mark"></i>	COMPUTER AIDED DRAFTING </li>
                                                <li><i class="icofont-tick-mark"></i> SURVEY USING MODERN INSTRUMENTS </li>
                                                <li><i class="icofont-tick-mark"></i>	THEODOLITE SURVEYING </li>
                                                <li><i class="icofont-tick-mark"></i>	BUILDING ESTIMATION </li>
                                                <li><i class="icofont-tick-mark"></i> 	familiarize the language of graphics which is used to express ideas, convey instructions while carrying out engineering jobs. </li>
                                                <li><i class="icofont-tick-mark"></i> 	familiarize drafting and sketching skills, to know the applications of drawing equipment’s, and get familiarize with Indian Standards related to engineering drawings </li>
                                                <li><i class="icofont-tick-mark"></i> 	develop the concept of basic principles of surveying including the uses of chain and plane table survey. </li>
                                                <li><i class="icofont-tick-mark"></i>	train students to use basic survey equipments like chain, cross staff, compass, levelling equipment  </li>
                                                <li><i class="icofont-tick-mark"></i>	Impart basic knowledge of the principles of building planning and drawing  </li>
                                                <li><i class="icofont-tick-mark"></i> 	Impart the knowledge of CAD commands for drawing 2D building drawings required for various civil engineering applications. </li>
                                            </ul>
                                            <p>In this course, you will learn about the different types of civil engineering projects, such as roads, bridges, buildings, and dams. You will also learn about how these projects are designed and constructed, and the challenges that civil engineers face. In addition, you will learn about the ethical and professional responsibilities of civil engineers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default AcPage;