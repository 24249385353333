import { useState, useEffect, React } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {env} from '../../env'

const subTitle = "FORM OUR BLOG POSTS";
const title = "More Articles From Resource Library";


const apiUrl = env.REACT_APP_API_URL;

const Blog = () => {
    const [blogList, setBlogList] = useState(null);

    useEffect(() => {
        axios.get(apiUrl + "/api/blogs/3")
            .then(response => {
                setBlogList(response.data.data);
            })
            .catch(error => {
                console.error(error.message);
            });
    }, []);
    return (
        <div className="blog-section padding-tb section-bg">
            <div className="container">
                <div className="section-header text-center">
                    <span className="subtitle">{subTitle}</span>
                    <h2 className="title">{title}</h2>
                </div>
                <div className="section-wrapper">
                    <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center g-4">
                        {(  blogList != null)?blogList.map((val, i) => (
                            <div className="col" key={i}>
                                <div className="post-item">
                                    <div className="post-inner">
                                        <div className="post-thumb">
                                            <Link to="/blog-single"><img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} /></Link>
                                        </div>
                                        <div className="post-content">
                                            <Link to="/blog-single"><h4>{val.title}</h4></Link>
                                            <div className="meta-post">
                                                <ul className="lab-ul">
                                                    <li><i className="icofont-ui-user"></i>{val.author}</li>
                                                    <li><i className="icofont-calendar"></i>{val.date}</li>
                                                </ul>
                                            </div>
                                            <p>{val.desc}</p>
                                        </div>
                                        <div className="post-footer">
                                            <div className="pf-left">
                                                <Link to="/blog-single" className="lab-btn-text">{val.btnText} <i className="icofont-external-link"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )):<div style={{ width:"100%", textAlign:"center" }}>Loading ...</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Blog;