import { Link } from "react-router-dom";


const subTitle = "Practical education";
const title = <h2 className="title"><span className="d-lg-block">Empowering</span>Agile Minds in <span className="d-lg-block">Technology</span></h2>;
const desc = "Unleash your potential in the world of technology at our esteemed Calicut institution. Experience nurturing, top-notch facilities, and experienced faculty.";

const Banner = () => {
    return (
        <section className="banner-section">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-10">
                            <div className="banner-content">
                                <h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6>
                                {title}
                                <p className="desc">{desc}</p>
                                <Link to='/apply' className="lab-btn orange-button">Apply Now</Link>
                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6">
                            <div className="banner-thumb">
                                <img src="assets/images/banner/01.webp" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;