
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';


import ScrollToTop from "./component/layout/ScrollToTop";
import ErrorPage from "./page/404";
import AboutPage from "./page/about";
import BlogPage from "./page/blog";
import BlogSingle from "./page/blog-single";
import ContactPage from "./page/contact";
import AcPage from "./page/courses/ac-engg";
import AutomobilePage from "./page/courses/automobile-engg";
import CivilPage from "./page/courses/civil-engg";
import ElectricalPage from "./page/courses/electrical-engg";
import ElectronicsPage from "./page/courses/electro-commu-engg";
import MechanicalPage from "./page/courses/mechanical-engg";
import ComputerPage from "./page/courses/computer-engg";
import Home from "./page/home";
import ApplyPage from "./page/apply";
import Affiliation from './page/affliation'


function App() {

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="ac-engineering" element={<AcPage />} />
				<Route path="automobile-engineering" element={<AutomobilePage />} />
				<Route path="civil-engineering" element={<CivilPage />} />
				<Route path="electrical-engineering" element={<ElectricalPage />} />
				<Route path="electronics-communication-engineering" element={<ElectronicsPage />} />
				<Route path="computer-engineering" element={<ComputerPage />} />
				<Route path="mechanical-engineering" element={<MechanicalPage />} />
				<Route path="blogs" element={<BlogPage />} />
				<Route path="blog-single" element={<BlogSingle />} />
				<Route path="about" element={<AboutPage />} />
				<Route path="contact" element={<ContactPage />} />
				<Route path="apply" element={<ApplyPage />} />
				<Route path="affiliation" element={<Affiliation />} />
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
