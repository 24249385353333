import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import About from "../component/section/about";
import Achievement from "../component/section/achievement";
import Banner from "../component/section/banner";
import Blog from "../component/section/blog";
import Category from "../component/section/category";
import Student from "../component/section/student";

import '../assets/css/my/homepage.css';

const Home = () => {
    return (
        <Fragment>
            <Header />
            <Banner />
            <Category />
            <About />
            {/* <Student />  */}
             <Blog />
            <Achievement />
            <Footer />
        </Fragment>
    );
}

export default Home;