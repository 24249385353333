import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import CourseSideDetail from "../../component/sidebar/course-detail";


const AcPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo
                title={"Electrical Engineering"}
                desc={"Electrical Engineering is the application of engineering principles to the study, design, and application of equipment, devices, and systems which use electricity, electronics, and electromagnetism."}
                author={"adish husain"}
                image={"https://randomuser.me/api/portraits/men/72.jpg"}
            />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div class="course-content">
                                            <h3>Course Overview</h3>
                                            <p>This course will teach you the fundamentals of electrical engineering. You will learn about the different types of electrical circuits, how they work, and how to design and build them. You will also learn about the different types of electrical power systems, how they work, and how to troubleshoot them.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul class="lab-ul">
                                                <li><i class="icofont-tick-mark"></i>	understand different instruments used in engineering drawing  </li>
                                                <li><i class="icofont-tick-mark"></i>	understand Geometric constructions and drawings of various objects and shapes  </li>
                                                <li><i class="icofont-tick-mark"></i>	draw orthographic projections of various objects  </li>
                                                <li><i class="icofont-tick-mark"></i>	Introduction to ELECTRICAL SAFETY AND EARTHING  </li>
                                                <li><i class="icofont-tick-mark"></i>	BASICS OF ELECTRICITY & MAGNETISM  </li>
                                                <li><i class="icofont-tick-mark"></i>	ELECTRICAL WIRING - DESIGNING ESTIMATION & COSTING  </li>
                                                <li><i class="icofont-tick-mark"></i>	understand Charging & Discharging of batteries used in electric vehicles  </li>
                                                <li><i class="icofont-tick-mark"></i>	understand functions of various lathe attachments & accessories  </li>
                                            </ul>
                                            <p>In this course, you will learn about the different types of electrical circuits, such as series circuits, parallel circuits, and combination circuits. You will also learn about how these circuits work, and how to design and build them. In addition, you will learn about the different types of electrical power systems, such as power plants, transmission lines, and distribution systems. You will also learn about how these systems work, and how to troubleshoot them.</p>
                                            <p>By the end of this course, you will be able to:</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default AcPage;