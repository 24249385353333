
import { Link } from "react-router-dom";

const excenge = "Admission Ongoing";
const btnText = "Enrolled Now";


const csdcList = [
    {
        iconName: 'icofont-book-alt',
        leftText: 'Course Duration',
        rightText: '2 year',
    },
    {
        iconName: 'icofont-certificate',
        leftText: 'Certificate',
        rightText: 'Deploma',
    },
    {
        iconName: 'icofont-globe',
        leftText: 'Language',
        rightText: 'Malayalam, English',
    },
]

const CourseSideDetail = () => {
    return (
        <div className="course-side-detail">
            <div className="csd-title">
                <div className="csdt-left">
                    <p className="mb-0"><i className="icofont-clock-time"></i> {excenge}</p>
                </div>
                <div className="csdt-right">
                </div>
            </div>
            <div className="csd-content">
                <div className="csdc-lists">
                    <ul className="lab-ul">
                        {csdcList.map((val, i) => (
                            <li key={i}>
                                <div className="csdc-left"><i className={val.iconName}></i>{val.leftText}</div>
                                <div className="csdc-right">{val.rightText}</div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="course-enroll">
                    <Link to="/signup" className="lab-btn"><span>{btnText}</span></Link>
                </div>
            </div>
        </div>
    );
}

export default CourseSideDetail;