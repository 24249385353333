import { Link } from "react-router-dom";

import '../../assets/css/my/homepage.css';

const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const siteTitle = "Quick Links";
const useTitle = "Useful Links";
const socialTitle = "Social Contact";


const siteList = [
    {
        text: 'Home',
        link: '/',
    },
    {
        text: 'About',
        link: '/about',
    },
    {
        text: 'Apply',
        link: '/apply',
    },
    {
        text: 'Blogs',
        link: '/blogs',
    },
    {
        text: 'Contact',
        link: '/contact',
    },
]

const useList = [
    {
        text: 'Automobile Engineering',
        link: '/automobile-engineering',
    },
    {
        text: 'A/C Refrigeration Engineering',
        link: '/ac-engineering',
    },
    {
        text: 'Civil Engineering',
        link: '/civil-engineering',
    },
    {
        text: 'Electronics & Communication Engineering',
        link: '/electronics-communication-engineering',
    },
    {
        text: 'Electrical Engineering',
        link: '/electrical-engineering',
    },
]

const socialList = [
    {
        text: 'Facebook',
        link: 'https://www.facebook.com/eti.areekod',
    },
    {
        text: 'Instagram',
        link: 'https://www.instagram.com/eti_institutions/',
    },
    {
        text: 'YouTube',
        link: 'https://www.youtube.com/@etiareekode7209',
    },
]
const supportList = [
    {
        text: 'Computer Engineering',
        link: '/computer-engineering',
    },
    {
        text: 'Mechanical Engineering',
        link: '/mechanical-engineering',
    },
    {
        text: 'Affiliation',
        link: '/affiliation',
    },
    {
        text: 'Call Us',
        link: 'tel:+919847406431',
    },
    {
        text: 'Email Support',
        link: 'mailto:info@etiinstitutions.org',
    },
]



const Footer = () => {
    return (
        <div className="news-footer-wrap">
            {/* <div className="fs-shape">
                <img sr c="assets/images/shape-img/03.webp" alt="fst" className="fst-1" />
                <img src="assets/images/shape-img/04.webp" alt="fst" className="fst-2" />
            </div>

            <div className="news-letter">
                <div className="container">
                    <div className="section-wrapper">
                        <div className="news-title">
                            <h3>{newsTitle}</h3>
                        </div>
                        <div className="news-form">
                            <form action="/">
                                <div className="nf-list">
                                    <input type="email" name="email" placeholder="Enter Your Email" />
                                    <input type="submit" name="submit" value="Subscribe Now" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

            <footer>
                <div className="footer-top padding-tb pt-0">
                    <div className="container">
                        <div className="row g-4 row-cols-xl-4 row-cols-md-2 row-cols-1 justify-content-center">
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{siteTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {siteList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{useTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {useList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>&#8202;</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {supportList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="footer-item">
                                    <div className="footer-inner">
                                        <div className="footer-content">
                                            <div className="title">
                                                <h4>{socialTitle}</h4>
                                            </div>
                                            <div className="content">
                                                <ul className="lab-ul">
                                                    {socialList.map((val, i) => (
                                                        <li key={i}><a href={val.link}>{val.text}</a></li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom style-2">
                    <div className="container">
                        <div className="section-wrapper">
                            <p>&copy; 2023 <Link to="/">ETI Institutions</Link> Designed by <a href="https://pensare.in/" target="_blank">Pensare</a> </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;