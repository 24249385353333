import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import CourseSideDetail from "../../component/sidebar/course-detail";


const AcPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo
                title={"Electronics Engineering"}
                desc={"Electronics Engineering is the application of engineering principles to the design, development, and manufacture of electronic devices and systems."}
                author={"adish husain"}
                image={"https://randomuser.me/api/portraits/men/68.jpg"}
            />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div class="course-content">

                                            <h3>Course Overview</h3>

                                            <p>This course will teach you the fundamentals of electronics engineering for computer engineering. You will learn about the different types of electronic components, how they work, and how to use them to design and build electronic circuits. You will also learn about the different types of electronic systems, how they work, and how to troubleshoot them.</p>

                                            <h4>What You'll Learn in This Course:</h4>

                                            <ul class="lab-ul">
                                                <li><i class="icofont-tick-mark"></i> 	Engineering Drawing     </li>
                                                <li><i class="icofont-tick-mark"></i>  	Tally     </li>
                                                <li><i class="icofont-tick-mark"></i>   	OFFICE AUTOMATION ASSISTANT Familiarisation with Operating System    </li>
                                                <li><i class="icofont-tick-mark"></i> 	Learning Microsoft Word, Microsoft Powepoint, Microsoft Excel      </li>
                                                <li><i class="icofont-tick-mark"></i>	Basic Formatting       </li>
                                                <li><i class="icofont-tick-mark"></i>	Digital Computer Fundamentals & Operating system       </li>
                                                <li><i class="icofont-tick-mark"></i> 	HARDWARE AND NETWORKING      </li>
                                                <li><i class="icofont-tick-mark"></i> 	Control & monitoring of network devices      </li>
                                                <li><i class="icofont-tick-mark"></i>  Familiarise the hardware components of the computer system.       </li>
                                                <li><i class="icofont-tick-mark"></i> Assemble and disassemble a computer system.    </li>
                                            </ul>

                                            <p>In this course, you will learn about the different types of electronic components, such as resistors, capacitors, inductors, and transistors. You will also learn about how these components work, and how to use them to design and build electronic circuits. In addition, you will learn about the different types of electronic systems, such as radio receivers, amplifiers, and digital circuits. You will also learn about how these systems work, and how to troubleshoot them.</p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default AcPage;