import { Component, Fragment } from "react";
import Footer from "../../component/layout/footer";
import Header from "../../component/layout/header";
import PageHeaderTwo from "../../component/layout/pageheader-2";
import CourseSideDetail from "../../component/sidebar/course-detail";

const MechPage = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo
                title={"Mechanical Engineering"}
                desc={"Mechanical engineering is the branch of engineering that involves the design, analysis, and manufacturing of mechanical systems."}
                author={"John"}
                image={"https://randomuser.me/api/portraits/men/42.jpg"}
            />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Course Overview</h3>
                                            <p>This course will provide you with a comprehensive understanding of mechanical engineering principles. You will learn about topics such as thermodynamics, fluid mechanics, materials science, and mechanical design. Additionally, you will gain hands-on experience through practical projects and laboratory work.</p>
                                            <h4>What You'll Learn in This Course:</h4>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>	Engineering Drawing </li>
                                                <li><i className="icofont-tick-mark"></i>	understand various hand tools in fitting section - Vices, Hammers, Chisels, Files, Scraper, Hacksaw, Surface plate, Scriber, Punches, V-Block, Angle plate, Try square, Drills, Reamer, Taps, Dies etc </li>
                                                <li><i className="icofont-tick-mark"></i>	Metal Joining Processes </li>
                                                <li><i className="icofont-tick-mark"></i>	Lathe & Lathe operations </li>
                                                <li><i className="icofont-tick-mark"></i>	familiar with the usage of fitting hand tools and operations </li>
                                                <li><i className="icofont-tick-mark"></i>	Sheet metal Practice </li>
                                                <li><i className="icofont-tick-mark"></i>	Welding Practice </li>
                                                <li><i className="icofont-tick-mark"></i>	Material Estimation & Costing </li>
                                                <li><i className="icofont-tick-mark"></i>	Welding - Arc & Gas </li>
                                                <li><i className="icofont-tick-mark"></i>	Fitting & Bench work </li>
                                                <li><i className="icofont-tick-mark"></i>	Machine Tools </li>
                                                <li><i className="icofont-tick-mark"></i>		Advance welding techniques – TIG  </li>
                                                <li><i className="icofont-tick-mark"></i>		Fitting , Bench work & Assembly  </li>
                                            </ul>
                                            <p>Throughout the course, you will develop problem-solving skills and learn how to apply engineering principles to real-world mechanical challenges. By the end of the course, you will have a solid foundation in mechanical engineering and be ready to pursue a career in the field.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default MechPage;
